// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null
}

const experiences = (state = initialState, action) => {
  switch (action.type) {
      case 'GET_ALL_EXPERIENCES':
      return { ...state, allData: action.data }
    case 'GET_DATA_EXPERIENCES':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':
      return { ...state, selectedUser: action.selectedUser }
    case 'EDIT_EXPER':
      return { ...state }
    case 'ADD_EXPER':
      return { ...state }
    case 'DELETE_EXPER':
      return { ...state }
    default:
      return { ...state }
  }
}
export default experiences
