// ** Redux Imports
import {combineReducers} from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import managers from '@src/views/apps/userManagers/store/reducer'
import projects from '@src/views/apps/project/store/reducer'


import estimatedPeriods from '@src/views/apps/estimatedPeriod/store/reducer'
import experiences from '@src/views/apps/experiences/store/reducer'
import fieldOfWorks from '@src/views/apps/fieldOfWorks/store/reducer'
import implementationSites from '@src/views/apps/implementationSite/store/reducer'
import languages from '@src/views/apps/languages/store/reducer'
import languageLevels from '@src/views/apps/languagesLevels/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'

const rootReducer = combineReducers({
    auth,
    todo,
    chat,
    email,
    users,
    managers,
    projects,
    estimatedPeriods,
    experiences,
    fieldOfWorks,
    implementationSites,
    languages,
    languageLevels,
    navbar,
    layout,
    invoice,
    calendar,
    ecommerce,
    dataTables
})

export default rootReducer
